import React from 'react';
import { Filter, TextInput } from 'react-admin';

const DailyGamesFilter = (props) => (
  <Filter {...props}>
    {/* <CustomDateInput label="Choose start date" source="start_date" start initialValue={'2023-07-20 13:00'} alwaysOn />
    <CustomDateInput label="Choose end date" source="end_date" end alwaysOn /> */}
    <TextInput label="User ID" source="user_id" alwaysOn />
  </Filter>
);

export default DailyGamesFilter;
