import DailyGamesIcon from '@material-ui/icons/Schedule';
import DailyGamesCreate from './components/DailyGamesCreate';
import DailyGamesEdit from './components/DailyGamesEdit';
import DailyGamesList from './components/DailyGamesList';
import { APP_TYPES, roles } from '../../config/constants';

const options = {
  appTypes: [APP_TYPES.HUNGARY, APP_TYPES.IRELAND],
  allowedRoles: [roles.ADMIN],
  label: 'Daily Game',
  subMenuName: 'content',
};

export default {
  create: DailyGamesCreate,
  list: DailyGamesList,
  edit: DailyGamesEdit,
  icon: DailyGamesIcon,
  name: 'admin/daily-games',
  options,
};
