import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import { CustomDateField } from '../../../../commonComponents/DateField';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';
import DailyGamesFilter from './DailyGamesFilter';

const DailyGamesList = (props) => (
  <List
    title="Daily Games reports"
    actions={<CustomReportListActions />}
    filters={<DailyGamesFilter />}
    bulkActionButtons={false}
    sort={{ field: 'user_id', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField label="User ID" source="id" sortBy="user_id" sortable={true} />
      <TextField label="User Phone" source="phone" sortable={false} />
      <CustomDateField label="Date created" source="created_at" sortable={false} />
    </Datagrid>
  </List>
);

export default DailyGamesList;
