import specialQuizzes from './specialQuizzes';
import quizzes from './quizzes';
import multibrandQuizzes from './multibrandQuizzes';
import quizRates from './quizRates';
import orders from './orders';
import leaderboard from './leaderboard';
import users from './users';
import chance from './chance';
import challenge from './challenge';
import transactions from './transactions';
import taxes from './taxes';
import inviteCodes from './inviteCodes';
import kbArticles from './usersKnowledgeBankArticles';
import dailyQuestions from './dailyQuestions';
import digitalProducts from './digitalProducts';
import promoCodes from './promoCodes';
import singleMode from './singleMode';
import surveys from './surveys';
import autolight from './autolight';
import salesTips from './salesTips';
import loyaltyPrograms from './loyaltyPrograms';
import productOrders from './productOrders';
import currentProducts from './currentProducts';
import salesSimulators from './salesSimulators';
import phoneTransfers from './bonusShopTransfers';
import newDownloads from './newDownloads';
import activities from './activity';
import boosters from './boosters';
import achievements from './achievements';
import transfers from './transfers';
import games from './games';
import lep from './lep';
import interactives from './interactives';
import bots from './bots';
import activityManagers from './managersActivity';
import quickTips from './quickTips';
import productQuestions from './quizProductQuestions';
import transactionManagers from './managerTransactions';
import winstonMemoryGame from './winstonMemoryGame';
import dailyGames from './daily-games';
import popup from './popup';

export default [
  popup,
  dailyGames,
  winstonMemoryGame,
  specialQuizzes,
  quizzes,
  productQuestions,
  quizRates,
  surveys,
  multibrandQuizzes,
  singleMode,
  dailyQuestions,
  orders,
  productOrders,
  currentProducts,
  autolight,
  digitalProducts,
  phoneTransfers,
  leaderboard,
  users,
  newDownloads,
  activities,
  chance,
  challenge,
  loyaltyPrograms,
  lep,
  transactions,
  boosters,
  taxes,
  inviteCodes,
  promoCodes,
  achievements,
  kbArticles,
  salesTips,
  salesSimulators,
  transfers,
  games,
  interactives,
  bots,
  activityManagers,
  quickTips,
  transactionManagers,
];
