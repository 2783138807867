import quizzes from '../quizzes';
import specialQuizzes from '../specialQuizzes';
import multibrandQuizzes from '../multibrandQuizzes';
import dailyQuestions from '../dailyQuestions';
import eventModeQuizzes from '../eventMode/quizzes';
import eventModeRewards from '../eventMode/rewards';
import singleMode from '../singleMode';
import salesTips from '../salesTips';
import surveys from '../surveys';
import salesSimulator from '../salesSimulator';
import challenges from '../challenges';
import chance from '../chance';
import promoCodes from '../promoCodes';
import loyaltyPrograms from '../loyaltyPrograms';
import dailyRewards from '../dailyRewards';
import rewards from '../rewards';
import achievements from '../achievements';
import independentItems from '../independentItems';
import lep from '../lep';
import interactive from '../interactive';
import interactiveModeration from '../interactiveModeration';
import banner from '../banner';
import dailyGames from '../dailyGames';
import arPacks from '../arPacks';
import arUnrecognizedPacks from '../arUnrecognizedPacks';
import quickTips from '../quickTips';
import mysteryShopper from '../mysteryShopper';

export default [
  banner,
  quizzes,
  specialQuizzes,
  multibrandQuizzes,
  dailyQuestions,
  eventModeQuizzes,
  eventModeRewards,
  singleMode,
  salesTips,
  surveys,
  salesSimulator,
  challenges,
  chance,
  promoCodes,
  loyaltyPrograms,
  dailyRewards,
  rewards,
  achievements,
  independentItems,
  lep,
  interactiveModeration,
  interactive,
  dailyGames,
  arPacks,
  arUnrecognizedPacks,
  quickTips,
  mysteryShopper,
];
