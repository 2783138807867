import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';

const DailyGamesList = (props) => (
  <List
    title="Daily Games"
    exporter={false}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
    hasCreate={false}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="Name" source="name" />
      <TextField label="URL" source="url" />
      <TextField source="article.name" label="Related Article" />
    </Datagrid>
  </List>
);

export default DailyGamesList;
