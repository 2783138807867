import React from 'react';
import { createBrowserHistory } from 'history';
import { Admin, Resource } from 'react-admin';

import { allRoles, APP_TYPES, roles, serverApiUrl } from './config/constants';
import CustomLayout from './core/customLayout/Layout';
import authProvider from './core/authProvider';
import serverAPIProvider from './core/serverAPIProvider';
import httpClient from './core/httpClient';
import customRoutes from './core/customRoutes';
import customTheme from './core/customTheme';
import i18nProvider from './i18n/i18nProvider';

import users from './pages/users';
import cpa from './pages/cpaProgram';
import statistics from './pages/statistics';
import managers from './pages/managers';
import administrators from './pages/administrators';
import loyaltyProgramAttachments from './pages/loyaltyPrograms/attachments';
import challengeAttachments from './pages/challenges/attachments';
import lepCodes from './pages/lep/codes';
import salesSimulatorScenarios from './pages/salesSimulator/scenarios';
import outlets from './pages/outlets';
import problems from './pages/problems';
import tickets from './pages/tickets';
import manageSubMenu from './pages/manage';
import logs from './pages/logs';
import contentSubMenu from './pages/content';
import knowledgeBank from './pages/knowledgeBank';
import winWinBonusShop from './pages/winWinBonusShop';
import bonusShop from './pages/bonusShop';
import chats from './pages/chats';
import reports from './pages/reports';
import tradeProgram from './pages/tradeProgram';
import ProviderProductsEdit from './pages/winWinBonusShop/providers/components/ProviderProductsEdit';
import mysteryShopperAttachments from './pages/mysteryShopper/attachments';
import outletsAndUsers from './pages/outletsAndUsers';

const history = createBrowserHistory();
const dataProvider = serverAPIProvider(serverApiUrl, httpClient);
const renderSubMenu = (resourceSettings) => <Resource key={resourceSettings.name} {...resourceSettings} />;

// https://source.unsplash.com/collection/nature/1600x900
const App = () => (
  <Admin
    layout={CustomLayout}
    customRoutes={customRoutes}
    history={history}
    authProvider={authProvider}
    dataProvider={dataProvider}
    i18nProvider={i18nProvider}
    theme={customTheme}
  >
    <Resource {...users} />
    <Resource {...statistics} />
    <Resource {...managers} />
    <Resource {...administrators} />
    <Resource {...loyaltyProgramAttachments} />
    <Resource {...challengeAttachments} />
    <Resource {...mysteryShopperAttachments} />
    <Resource {...lepCodes} />
    <Resource {...salesSimulatorScenarios} />
    <Resource {...outletsAndUsers} />
    {outlets.map(renderSubMenu)}
    {manageSubMenu.map(renderSubMenu)}
    <Resource
      {...{
        ...logs,
        options: {
          ...logs.options,
          allowedRoles: [roles.JTI_TEAM, roles.ADMIN],
          subMenuName: null,
          appTypes: [APP_TYPES.BELARUS],
        },
      }}
    />
    {contentSubMenu.map(renderSubMenu)}
    {knowledgeBank.map(renderSubMenu)}
    {winWinBonusShop.map(renderSubMenu)}
    {bonusShop.map(renderSubMenu)}
    {problems.map(renderSubMenu)}
    <Resource {...tickets} />
    <Resource {...chats} />
    {reports.map(renderSubMenu)}
    <Resource {...cpa} />
    <Resource {...tradeProgram} />
    <Resource
      options={{ allowedRoles: [roles.ADMIN, roles.JTI_TEAM] }}
      name="admin/products"
      edit={ProviderProductsEdit}
    />
    <Resource options={{ allowedRoles: allRoles }} name="admin/messages" />
    <Resource options={{ allowedRoles: allRoles }} name="services/positions" />
    <Resource options={{ allowedRoles: allRoles }} name="services/mobile-operators" />
    <Resource options={{ allowedRoles: allRoles }} name="admin/services/regions" />
    <Resource options={{ allowedRoles: allRoles }} name="services/brands" />
    <Resource options={{ allowedRoles: allRoles }} name="admin/colors" />
    <Resource options={{ allowedRoles: allRoles }} name="admin/services/frequencies" />
    <Resource options={{ allowedRoles: allRoles }} name="admin/services/outlet-types" />
    <Resource options={{ allowedRoles: allRoles }} name="admin/services/unexpected-rewards-levels" />
    <Resource options={{ allowedRoles: allRoles }} name="admin/users/autocomplete" />
    <Resource options={{ allowedRoles: allRoles }} name="admin/problems/2/problems/autocomplete" />
    <Resource options={{ allowedRoles: allRoles }} name="admin/problems/3/problems/autocomplete" />
    <Resource options={{ allowedRoles: allRoles }} name="admin/managers/autocomplete" />
    <Resource options={{ allowedRoles: allRoles }} name="admin/knowledge-bank/articles/autocomplete" />
    <Resource options={{ allowedRoles: allRoles }} name="admin/interactive-articles/types" />
    <Resource options={{ allowedRoles: allRoles }} name="admin/legal-ids/autocomplete" />
    <Resource options={{ allowedRoles: allRoles }} name="admin/cities/autocomplete" />
    <Resource options={{ allowedRoles: allRoles }} name="admin/legal-entities/autocomplete" />
    <Resource options={{ allowedRoles: allRoles }} name="admin/bonus-shop/category-types" />
    <Resource options={{ allowedRoles: allRoles }} name="admin/bonus-shop/categories/physical" />
    <Resource options={{ allowedRoles: allRoles }} name="admin/bonus-shop/categories/digital" />
    <Resource options={{ allowedRoles: allRoles }} name="admin/event-mode/physical-products" />
    <Resource options={{ allowedRoles: allRoles }} name="admin/event-mode/digital-products" />
    <Resource options={{ allowedRoles: allRoles }} name="admin/bonus-shop/presents/types" />
    <Resource options={{ allowedRoles: allRoles }} name="admin/reports/orders/types" />
    <Resource options={{ allowedRoles: allRoles }} name="admin/reports/verifications/types" />
    <Resource options={{ allowedRoles: allRoles }} name="admin/main-screen/components/types" />
    <Resource options={{ allowedRoles: allRoles }} name="admin/main-screen/box-components/types" />
    <Resource options={{ allowedRoles: allRoles }} name="admin/monthly-limitations/activity-types" />
    <Resource options={{ allowedRoles: allRoles }} name="admin/reactions/types" />
    <Resource options={{ allowedRoles: allRoles }} name="admin/knowledge-bank/categories/sections" />
  </Admin>
);

export default App;
